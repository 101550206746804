<template>
  <div class="subscribe bg-white">
    <SubscribeAddress
      :expanded="expandedSection === 'address'"
      :form-disabled="formDisabled"
      class="px-sm py-lg"
      @address-added="onAddressAdded"
      @edit-click="expandedSection = 'address'"
      @exit-click="setNextExpandedSection"/>
    <SubscribePayment
      :expanded="expandedSection === 'payment-method'"
      :form-disabled="formDisabled"
      :stripe="stripe"
      :card="card"
      :token="stripeToken"
      :can-show-payment-request="canShowPaymentRequest"
      :apple-pay="showApplePay"
      class="px-sm py-lg"
      @token-updated="onStripeTokenUpdated"
      @edit-click="expandedSection = 'payment-method'"
      @exit-click="setNextExpandedSection"
      @edit-payment-mounted="createCard('card-element')"/>
    <SubscribeSummary
      :expanded="expandedSection === 'summary'"
      :form-disabled="formDisabled"
      :fine-print-text="finePrintText"
      class="px-sm py-lg"
      @review-click="expandedSection = 'summary'">
      <p
        v-if="stripeErrorMessage"
        class="text-danger text-center small-text mb-sm">
        {{ stripeErrorMessage }}<br>
        <span v-html="stripeErrorFollowup"/>
      </p>
      <BaseButton
        :text="confirmButtonText"
        :disabled="confirmButtonDisabled"
        class="w-100 mb-xs"
        :class="{ 'apple-pay-button': confirmButtonIsApplePay }"
        @click="confirmAndPay">
        <template
          v-if="confirmButtonIsApplePay"
          #icon>
          <div class="apple-pay-logo"/>
        </template>
      </BaseButton>
    </SubscribeSummary>
  </div>
</template>

<script setup>
import SubscribeAddress from '@/components/subscribe/SubscribeAddress.vue'
import SubscribePayment from '@/components/subscribe/SubscribePayment.vue'
import SubscribeSummary from '@/components/subscribe/SubscribeSummary.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import { useSubscriptionStore } from '@/stores/subscribe.js'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useClientStore } from '@shared/stores/client.js'
import useStripe from '@shared/composables/stripe.js'
import { useLogger } from 'vue-logger-plugin'
import useAnalytics from '@shared/composables/analytics.js'
import usePayment from '@shared/composables/payment.js'
import { useRouter } from 'vue-router'
import { reportToRakuten } from '@/components/global/conversion/rakuten.js'
import useMobileApp, { MobileAppMessage } from '@/composables/mobileApp.js'
import { formatPrice } from '@/utils/stringParsing.js'
import { captureException } from '@sentry/vue'

const logger = useLogger()
const { track } = useAnalytics()
const router = useRouter()

const mobileApp = useMobileApp()

const subscriptionStore = useSubscriptionStore()
const {
  membershipPlanSelected,
  stripeToken,
  referralCode,
  referralSource,
  leadSource,
  leadSourceDetail,
  leadSourceDetailRequired,
  enteredCodes,
  tax
} = storeToRefs(subscriptionStore)
const {
  subscribeClient,
  callGetSubscriptionQuote,
  SET_STRIPE_TOKEN
} = subscriptionStore

const clientStore = useClientStore()
const {
  firstName,
  lastName,
  email,
  mainPhone,
  shippingAddress,
  zipCode,
  membershipStatus
} = storeToRefs(clientStore)
const { postDefaultPayment } = clientStore

const {
  card,
  stripe,
  loadStripe,
  untilStripeLoaded,
  initializeStripe,
  createCard
} = useStripe()

const { promoName, promoAmount, subtotal, total } = usePayment()

const expandedSection = ref('address')
const stripeErrorMessage = ref(null)
const stripeErrorFollowup = ref(null)
const paymentRequest = ref(null)
const canShowPaymentRequest = ref(false)
const showApplePay = ref(false)
const formDisabled = ref(false)
const loadingPaymentMethodInfo = ref(true)

const confirmButtonText = computed(() => {
  if (!shippingAddress.value) {
    return 'Shipping Address Required'
  }
  if (!canShowPaymentRequest.value && !stripeToken.value) {
    return 'Payment Method Required'
  }
  if (!stripeToken.value && showApplePay.value) {
    return 'Subscribe with'
  }
  const action = canShowPaymentRequest.value && !stripeToken.value ? 'Review Express Payment' : 'Pay'
  return `Confirm and ${action} — ${formatPrice(total.value, false, true)}`
})
const confirmButtonDisabled = computed(() =>
  formDisabled.value ||
  !shippingAddress.value ||
  !leadSource.value ||
  (!stripeToken.value && !canShowPaymentRequest.value) ||
  (leadSourceDetailRequired.value && !leadSourceDetail.value)
)
const confirmButtonIsApplePay = computed(() => shippingAddress.value && showApplePay.value && !stripeToken.value)
const finePrintText = computed(() => {
  if ((!stripeToken.value && !canShowPaymentRequest.value) || !shippingAddress.value || !leadSource.value) {
    return ''
  }
  if (!stripeToken.value && showApplePay.value) {
    return `${confirmButtonText.value} Apple Pay`
  }
  return confirmButtonText.value
})

watch(total, (total) => {
  if (paymentRequest.value) {
    paymentRequest.value.update({
      total: {
        label: 'Armoire Subscription',
        amount: Math.round(total * 100)
      }
    })
  }
})

onBeforeMount(() => {
  loadStripe()
})
onMounted(async () => {
  initializePaymentButton()
})

async function initializePaymentButton () {
  await untilStripeLoaded()
  await initializeStripeElement()
  if (shippingAddress.value) {
    logger.info('Shipping address already present, getting subscription quote', shippingAddress.value)
    await callGetSubscriptionQuote()
  }
  setNextExpandedSection()
}

async function initializeStripeElement () {
  logger.info('Initializing Stripe Element')
  const elements = await initializeStripe()
  logger.info('Stripe initialized')
  paymentRequest.value = stripe.value.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: {
      label: 'Armoire Subscription',
      amount: Math.round(total.value * 100)
    },
    requestPayerName: true,
    requestPayerEmail: true
  })

  logger.info('Creating Stripe payment request button')
  await elements.create('paymentRequestButton', {
    paymentRequest: paymentRequest.value
  })

  // Check the availability of the Payment Request API first.
  onCanMakePayment(await paymentRequest.value.canMakePayment())
  paymentRequest.value.on('token', onPaymentRequestToken)
}

function onCanMakePayment (result) {
  if (result) {
    canShowPaymentRequest.value = true
    if (result.applePay) {
      showApplePay.value = true
    }
  }
  loadingPaymentMethodInfo.value = false
  if ((canShowPaymentRequest.value || stripeToken.value) && shippingAddress.value) expandedSection.value = 'summary'
}

function onPaymentRequestToken (res) {
  subscribeClientWithToken(res.token, res)
  track('Express payment used')
}

async function subscribeClientWithToken (token, onComplete = null) {
  formDisabled.value = true
  const data = {
    token,
    upgradeCode: '',
    plan: membershipPlanSelected.value.name,
    leadSource: getLeadSource(),
    promo_codes: enteredCodes.value
  }
  if (referralCode.value) {
    data.referrer_code = referralCode.value
    data.referral_source = referralSource.value
  }
  // create a copy of this data to use in the Segment Order Completed
  // event, since it gets cleared as part of subscribeClient
  const orderCompleteData = {
    tax: (tax.value / 100).toFixed(2),
    subtotal: subtotal.value.toFixed(2),
    total: total.value,
    discount: Number.parseFloat(promoAmount.value).toFixed(2),
    coupon: promoName.value
  }
  let sendAnalytics = true
  let subResponse = null
  try {
    if (membershipStatus.value !== 'active') {
      subResponse = await subscribeClient(data)
      if ('stripeError' in subResponse.data) {
        logger.error('subscribeClient failure', subResponse.data)
        stripeErrorMessage.value = subResponse.data.stripeError.errorMessage
        stripeErrorFollowup.value = subResponse.data.stripeError.errorFollowup
        SET_STRIPE_TOKEN(null)
        sendAnalytics = false
        if (onComplete) {
          onComplete.complete('fail')
          return
        }
      }
    } else {
      await postDefaultPayment(token)
    }
    if (sendAnalytics) {
      reportSubscription(orderCompleteData, subResponse)
    }
    completeSubscription(onComplete)
  } catch (err) {
    captureException(err)
    logger.error(err)
    stripeErrorMessage.value = `There was an issue confirming your subscription. Please ${stripeToken.value ? 're-enter your payment information above and' : ''} try again.`
    stripeErrorFollowup.value = ''
    err.forEach?.(error => { stripeErrorFollowup.value += `<br>${error}` })
    SET_STRIPE_TOKEN(null)
  } finally {
    formDisabled.value = false
  }
}

function completeSubscription (onComplete) {
  onComplete?.complete('success')
  router.push({ name: 'sign-up-next-steps' })
}

function getLeadSource () {
  return {
    name: leadSource.value,
    detail: leadSourceDetail.value,
    utmCampaign: sessionStorage.getItem('utm_campaign'),
    utmMedium: sessionStorage.getItem('utm_medium'),
    utmSource: sessionStorage.getItem('utm_source')
  }
}

function setNextExpandedSection () {
  if (!shippingAddress.value) {
    expandedSection.value = 'address'
  } else if (!stripeToken.value && !canShowPaymentRequest.value) {
    expandedSection.value = 'payment-method'
  } else {
    expandedSection.value = 'summary'
  }
}

watch(expandedSection, (expandedSection) => {
  logger.info('Expanded section changed to', expandedSection)
})

async function confirmAndPay () {
  if (stripeToken.value) {
    await subscribeClientWithToken(stripeToken.value)
  } else if (canShowPaymentRequest.value) {
    paymentRequest.value.show()
  }
}

function reportSubscription (orderCompleteData, res) {
  try {
    const analyticsData = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: mainPhone.value.replace(/-/g, ''),
      zipCode: zipCode.value,
      promoCode: membershipPlanSelected.value?.appliedPromo.promoCode ?? '',
      planName: membershipPlanSelected.value.name,
      planId: membershipPlanSelected.value.id,
      leadSource: leadSource.value,
      leadSourceDetail: leadSourceDetail.value,
      order_id: res.data.rakuten.orderid,
      affiliation: 'Armoire',
      total: orderCompleteData.total,
      revenue: orderCompleteData.subtotal,
      value: orderCompleteData.subtotal,
      shipping: 0,
      tax: orderCompleteData.tax,
      discount: orderCompleteData.discount,
      coupon: orderCompleteData.coupon,
      currency: 'USD',
      products: [
        {
          product_id: membershipPlanSelected.value.id,
          name: membershipPlanSelected.value.name,
          price: orderCompleteData.subtotal,
          quantity: 1,
          category: 'Subscription'
        }
      ]
    }
    logger.info(analyticsData)
    track('Order Completed', analyticsData)
    // DO NOT REMOVE
    // This looks redundant but it is necessary. Segment does special handling of
    // Order Completed that doesn't work well for Facebook, they map it to Purchased.
    // We work around Segment by sending a second event with the same payload.
    track('Client Subscribed - Facebook', analyticsData)
    reportToRakuten(res.data.rakuten)
  } catch (err) {
    captureException(err)
    // don't stop on or surface errors that are analytics/3rd party based
    logger.error(err)
  } finally {
    mobileApp.post(MobileAppMessage.Subscribed, res.data)
  }
}

function onAddressAdded () {
  track('Subscribe - Shipping Address Added')
  setNextExpandedSection()
  callGetSubscriptionQuote()
}

function onStripeTokenUpdated (token) {
  SET_STRIPE_TOKEN(token)
  stripeErrorMessage.value = null
  stripeErrorFollowup.value = null
  setNextExpandedSection()
}
</script>

<style lang="scss" scoped>
.subscribe {
  border: 0.5px solid $ash;
  border-radius: $sequin-border-radius;

  @include media-tablet-or-larger {
    width: 636px;
  }

  > * {
    border: none !important;
    padding: 1rem;
  }
  > *:not(:last-child) {
    border-bottom: 0.5px solid $ash !important;
  }

  .apple-pay-button {
    background-color: #000 !important;
    color: #fff !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI' !important;
    font-weight: $font-weight-semibold !important;
    flex-direction: row-reverse;

    &:hover {
      background-color: #000;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  .apple-pay-logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-size: 100% 100%;
    background-origin: content-box;
    background-repeat: no-repeat;
    height: 20px;
    width: 52px;
  }
}
</style>
